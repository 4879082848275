<script>
// TODO: инициализацию карты в mounted. Created срабатывает слишком рано

// Vue libs
import Vue from 'vue';

// Сторонние зависимости
import MapBoxGL from 'mapbox-gl';

// Utils
import Map from '@/utils/map';
import {setPadding} from '@/utils/utils';

// Сервисы
import VehicleService from '@/services/VehicleService';
import MapLayersService from '@/services/MapLayersService';
import CarrierUtils from './service/CarrierUtils';
import MapObjectsService from './service/MapObjectsService';
import PtMonMapService from './service/PtMonMapService';

//const WS_URI = process.env.VUE_APP_BACKEND_WS;

const states = {
  EVT_MAP_LOADING: 1,
  EVT_MAP_LOADED: 2,
  EVT_VC_LOADING: 3,
  EVT_VC_LOAD: 4
};

const modes = {
  GRP_NONE: 0,
  // По перевозчикам
  GRP_BY_CARRIERS: 'GRP_BY_CARRIERS',
  // По перевозчикам-маршрутам
  GRP_BY_CARRIERS_ROUTES: 'GRP_BY_CARRIERS_ROUTES',
  // По маршрутам-перевозчикам
  GRP_BY_ROUTES_CARRIERS: 'GRP_BY_ROUTES_CARRIERS',
  // По маршрутам-выездам
  GRP_BY_ROUTES_DEPS: 'GRP_BY_ROUTES_DEPS',
  // По МО-перевозчикам
  GRP_BY_ARS_CARRIERS: 'GRP_BY_ARS_CARRIERS',
  // По группам
  GRP_BY_GRPS_CARRIERS: 'GRP_BY_GRPS_CARRIERS',
};

const _groupComponents = {
  'GRP_BY_CARRIERS': 'TwoLevelGroup',
  'GRP_BY_CARRIERS_ROUTES': 'ThreeLevelGroup',
  'GRP_BY_ROUTES_CARRIERS': 'ThreeLevelGroup',
  'GRP_BY_ROUTES_DEPS': 'ThreeLevelGroup',
  'GRP_BY_ARS_CARRIERS': 'ThreeLevelGroup',
  'GRP_BY_GRPS_CARRIERS': 'ThreeLevelGroup',
};

// Шина событий
const _eventBus = new Vue();

// События
const _events = {
  // Скрытие всех машинок
  hideAllTracks: 'hardHideTracks',
  // Скрытие ранее отслеживаемой машинки
  hideAllTracking: 'hideTrackingCars',
  // Произошла фильтрация
  filtered: 'onVehiclesFiltered',
  // Данные для журнала переданы
  journalData: 'onJournalData',
};

// События ТС
const _lastEvents = CarrierUtils.lastEvents();

//let _nats = null;
//let _wsBus = null;

let _lastTracking = null;
let _sid = null;

let _playerPopup = null;

// Заголовок списка
const CarrierListHeader = {
  props: {
    // Заголовок
    title: {
      type: String,
      required: true,
    },
    // Идентификатор загрузки
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  name: 'CarrierListHeader',
  data: () => ({
    // Варианты группировок
    groups: [
      {name: 'По перевозчикам', mode: modes.GRP_BY_CARRIERS},
      {name: 'По перевозчикам-маршрутам', mode: modes.GRP_BY_CARRIERS_ROUTES},
      {name: 'По маршрутам-перевозчикам', mode: modes.GRP_BY_ROUTES_CARRIERS},
      {name: 'По маршрутам-выездам', mode: modes.GRP_BY_ROUTES_DEPS},
      {name: 'По МО-перевозчикам', mode: modes.GRP_BY_ARS_CARRIERS},
      {name: 'По группам', mode: modes.GRP_BY_GRPS_CARRIERS}
    ],
    // Показ диалога фильтра
    isShowFilter: false,
    // Объект формы фильтрации
    filterForm: {
      // Текст фильтрации
      text: '',
      // Статусы ТС
      statuses: {
        inState: true,
        fail2to24: true,
        fail24to72: true,
        failUp72: true,
        noData: true,
      },
      // Статус движения
      moving: {
        // Движется
        move: true,
        // Остановка
        stop: true,
        // Стоянка
        parking: true,
        // Заблокирован
        blocked: true,
        // Тревожная кнопка
        alertButton: false,
      },
    },
    // Текущие параметры фильтра
    currentFilter: {},
  }),
  methods: {
    // Очистка всех треков
    clearTracks() {
      this.$root.$emit(CarrierUtils.events.hideAllTracks);

      Map.clearTracks();
    },
    // Показать меню фильтрации
    showFilterMenu() {
      this.isShowFilter = !this.isShowFilter;
    },
    // Очистка формы фильтрации
    clearFilterForm() {
      this.filterForm = {
        text: '',
        statuses: {
          inState: true,
          fail2to24: true,
          fail24to72: true,
          failUp72: true,
          noData: true,
        },
        moving: {
          move: true,
          stop: true,
          parking: true,
          blocked: true,
          alertButton: false,
        }
      };
    },
    // Закрытие формы фильтрации
    closeFilterForm() {
      this.isShowFilter = false;

      this.filterForm = _copy(
        this.currentFilter.hasOwnProperty('text') ? this.currentFilter : this.filterForm
      );
    },
    // Применение фильтрации по тексту
    applyTextFilter(query) {
      this.filterForm.text = query;

      this.applyFilter();
    },
    // Выполненение фильтрации
    applyFilter() {
      this.currentFilter = _copy(this.filterForm);

      this.$emit('filter', this.currentFilter);

      this.isShowFilter = false;
    },
    // Выбор типа группировки
    onGroup(group) {
      this.$emit('group', group);
    }
  },
  template: `
    <v-card class="mx-auto">
    <v-dialog
      persistent
      v-model="isShowFilter"
      max-width="550"
    >
      <v-card>
        <v-card-title>
          Фильтрация
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12">
                  <span class="body-1 font-weight-bold">
                    Статус связи
                  </span>

                <v-row>
                  <v-col cols="6">
                    <v-checkbox
                      hide-details
                      v-model="filterForm.statuses.fail2to24"
                      type="checkbox"
                      label="Потеря связи с датчиком от 2 до 24 часов"
                    ></v-checkbox>

                    <v-checkbox
                      hide-details
                      v-model="filterForm.statuses.fail24to72"
                      type="checkbox"
                      label="Потеря связи с датчиков от 24 до 72 часов"
                    ></v-checkbox>

                    <v-checkbox
                      hide-details
                      v-model="filterForm.statuses.failUp72"
                      type="checkbox"
                      label="Потеря связи с датчиком свыше 72 часов"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="6">
                    <v-checkbox
                      hide-details
                      v-model="filterForm.statuses.inState"
                      type="checkbox"
                      label="На связи"
                    ></v-checkbox>

                    <v-checkbox
                      hide-details
                      v-model="filterForm.statuses.noData"
                      type="checkbox"
                      label="Нет данных"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                  <span class="body-1 font-weight-bold">
                    Статус движения
                  </span>

                <v-row>
                  <v-col cols="6">
                    <v-checkbox
                      hide-details
                      v-model="filterForm.moving.move"
                      type="checkbox"
                      label="Движется"
                    ></v-checkbox>

                    <v-checkbox
                      hide-details
                      v-model="filterForm.moving.stop"
                      type="checkbox"
                      label="Остановка"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="6">
                    <v-checkbox
                      hide-details
                      v-model="filterForm.moving.parking"
                      type="checkbox"
                      label="Стоянка"
                    ></v-checkbox>

                    <v-checkbox
                      hide-details
                      v-model="filterForm.moving.blocked"
                      type="checkbox"
                      label="Заблокирован"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox
                      hide-details
                      v-model="filterForm.moving.alertButton"
                      type="checkbox"
                      label="Тревожная кнопка"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="clearFilterForm">
            Очистить
          </v-btn>

          <v-spacer/>

          <v-btn @click="closeFilterForm">
            Отмена
          </v-btn>

          <v-btn color="primary" @click="applyFilter">
            Применить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-toolbar>
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>

      <v-spacer/>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="clearTracks">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>

        <span>Очистка треков</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="showFilterMenu">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>

        <span>Фильтры</span>
      </v-tooltip>

      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon v-on="on"/>
        </template>

        <v-list subheader dense>
          <v-subheader>
            Группировка ТС
          </v-subheader>

          <v-list-item-group>
            <v-list-item
              v-for="group in groups"
              :key="group.mode"
              @click="onGroup(group)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ group.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-divider/>

        </v-list>
      </v-menu>
    </v-toolbar>

    <v-card-text>
      <v-text-field
        clearable
        hide-details
        label="Фильтрация"
        placeholder="Имя перевозчика либо ТС"
        :disabled="loading"
        @change="applyTextFilter($event)"
        @click:clear="applyTextFilter(null)"
      ></v-text-field>
    </v-card-text>
    </v-card>
  `,
}; // CarrierListHeader

// Компонент списка
const CarrierList = {
  components: {
    // Заголовок
    CarrierListHeader,
    // Типы группировок
    TwoLevelGroup, ThreeLevelGroup,
  },
  props: {
    // Заголовок
    title: {
      type: String,
      required: true,
    },
  },
  name: 'CarrierList',
  created() {
    // При старте получаем данные
    this.getData().then(() => {
      // И начинаем наш путь с группировки
      this.onGroup();
    });
  },
  data: () => ({
    // Индикатор что происходит загрузка
    isLoading: false,
    // Сырые данные - список ТС
    vehicles: [],
    // Текущая группировка
    currentGroup: null,
    // Текущий фильтр
    currentFilter: null,
    // Типы состояний компонента (Фильтрация / Группировка)
    types: {
      // Группировка
      group: 'group',
      // Фильтрация
      filter: 'filter',
    },
    // Текущее состояние компонента
    currentType: null,
    // Данные полученные после группировки
    items: {},
    // Имя компонента который будет отображен
    listComponent: null,
    // Индикатор грузятся данные для группировки
    loadingGroupingData: false,
    // Старый тип группировки
    oldGroup: null,
  }),
  methods: {
    // Смена типа группировки
    onGroup(group) {
      this.oldGroup = this.currentGroup;

      this.currentGroup = (group && group.mode) || modes.GRP_BY_CARRIERS;
      this.currentType = this.types.group;

      this.changeComponent();
    },
    // Применение фильтрации
    onFilter(filter) {
      const filterData = _copy(filter);

      this.oldGroup = this.currentGroup;

      this.currentFilter = filterData;
      this.currentType = filterData.text === null ? this.types.group : this.types.filter;

      if (filterData.text === null && this.oldGroup !== null) {
        this.currentGroup = this.oldGroup;
        this.oldGroup = null;
      }

      this.changeComponent();
    },
    // Смена компонента для отображения данных
    changeComponent() {
      switch (this.currentType) {
        case this.types.group:
          const groupType = this.currentGroup || modes.GRP_BY_CARRIERS;

          this.loadingGroupingData = true;
          this.items = {};

          CarrierUtils.onGroupData(groupType, this.vehicles)
            .then(data => {
              this.items = _copy(data);

              this.listComponent = _groupComponents[groupType];
            })
            .finally(() => {
              this.loadingGroupingData = false;
            });

          break;

        // Для фильтров всегда возвращаем компонент Перевозчик - ТС
        case this.types.filter:
          CarrierUtils.onGroupData(modes.GRP_BY_CARRIERS, this.vehicles)
            .then(data => {
              this.items = _copy(data);

              this.listComponent = 'TwoLevelGroup';
            })
            .finally(() => {
              this.loadingGroupingData = false;
            });

          break;

        default:
          this.listComponent = null;
      }
    },
    // Получение данных
    async getData() {
      this.isLoading = true;

      // Получение списка ТС
      let vehicles = await VehicleService.getList();

      // Получение последнего статуса
      vehicles = await this._getLastTracks(vehicles);

      // Получение информации о аренде авто
      vehicles = await this.getRent(vehicles);

      this.vehicles = _copy(vehicles);

      this.isLoading = false;
    },
    // Получение трека за последние 10 минут
    async _getLastTracks(vehicles) {
      const currentTime = new Date().getTime();
      const time = currentTime - 10 * 60 * 1000;

      let toRequest = [];
      let lap = 0;
      const chunkSize = 45;
      const vLen = vehicles.length;

      // Получение треков за последние 10 минут
      for (let i = 0; i < vLen; i++) {
        if (i % chunkSize !== 0 || i === 0) {
          toRequest.push(vehicles[i]);

          continue;
        }

        lap++;

        // Получение треков
        const tracks = await VehicleService.getHistoryTrack(
          toRequest,
          new Date(time),
          new Date()
        );

        // Получение информации о ТС TODO: может и не надо?
        const lastInfo = await VehicleService.getLastInfo(
          toRequest.map(it => it.id)
        );

        if (lastInfo) {
          lastInfo.forEach(it => {
            const oId = it['originalId'];
            const deviceId = it.deviceId;
            const chunk = lap * chunkSize;

            for (let j = (chunk - chunkSize); j < chunk; j++) {
              const vehicle = vehicles[j];

              if (vehicle.id === deviceId) {
                const track = tracks.filter(it => it.id === oId)[0] || {};
                const points = track && track.points || [];

                vehicle['last_status'] = this._getLastStatus(points);

                break;
              }
            }
          });
        }

        toRequest = [];
      }

      return vehicles;
    },
    // Отслеживание
    async tracking(data, isTracking) {
      const id = data.vehicle.id;

      if (_lastTracking !== id) {
        _lastTracking = id;

        /*if (_sid !== null) {
          _wsBus.unsubscribe(_sid);

          _sid = null;
        }*/
      }

      if (isTracking) {
        const info = await VehicleService.getLastInfo(id);

        /*try {
          _sid = _wsBus.subscribe(`PUBLIC.kigat.telemetry.${info.deviceId}`, (msg) => {
            let payload = {};

            try {
              payload = JSON.parse(msg)
            } catch (e) {
              console.error('Nats error:', e);
            }

            Map.drawDynamicVehicle(payload, info, (props) => this._getVehicleInfo(props));
          });
        } catch (e) {
          console.error('Tracking error', e);

          jet.msg({
            text: 'Произошла ошибка во время запроса слежения за ТС',
          });
        }*/

        Map.drawDynamicVehicle(info, info, (props) => this._getVehicleInfo(props));
      }/* else {
        if (_sid !== null) {
          _wsBus.unsubscribe(_sid);
        }
      }*/
    },
    // Получение информации о аренде машин
    async getRent(vehicles) {
      const rent = await VehicleService.getCarrierRent();

      for (let i = 0, len = vehicles.length; i < len; i++) {
        const it = vehicles[i];

        const idx = rent.findIndex(r => r.carrierid === it.acid);

        if (idx > -1) {
          it['rent'] = rent[idx];
        }
      }

      return vehicles;
    },
    // Получение последнего статуса ТС
    _getLastStatus(points) {
      if (!points || points.length === 0) {
        return _lastEvents.blocked;
      }

      const pLength = points.length;

      // Берем последнее событие
      const lastPoint = points[points.length - 1];

      // Если нажата тревожная кнопка
      if (lastPoint.type === 'ALARM_BUTTON') {
        return _lastEvents.alarm;
      }

      // Если скорость отлична от 0 - значит движется
      if (lastPoint.speed > 0) {
        return _lastEvents.moving;
      }

      // Если скорость 0, то это либо остановка, либо парковка
      if (lastPoint.speed === 0) {
        let nonZeroSpeed = null

        for (let i = pLength - 2; i > -1; i--) {
          // Если в промежутке есть скорость отличная от нуля
          if (points[i].speed > 0) {
            // То сохраним ее
            nonZeroSpeed = points[i];
          }
        }

        // Если не нашли увеличение скорости
        if (nonZeroSpeed === null) {
          // То сверим даты
          const diff = lastPoint.time - points[0].time;
          const minutes = Math.abs((diff / (1000 * 60)) % 60);

          // Если в промежутке до 20 минут
          if (minutes >= 1 || minutes <= 20) {
            return _lastEvents.stop;
          }

          // Если больше 20 минут
          if (minutes > 20) {
            return _lastEvents.parking;
          }
        }
      }

      return _lastEvents.blocked;
    },
    // Получение информации о выбранной машинке
    async _getVehicleInfo(properties) {
      // Последняя активность
      const lastActive = await jet.http.post(`/publicApi?call=lastVehicle&arg.ids=${properties.deviceId}`);
      const lastActiveData = lastActive && lastActive.length > 0 && lastActive[0] || {};

      // Информация о ТС
      const vehicleResponse = await jet.http.post(`/rpc?d=jsonRpc`, {
        type: 'query',
        query: '4ff9688e-277b-48a0-b211-b1e874e6f5f9.cbVehicles',
        params: {
          tenantId: '00000000-0000-0000-0000-0000000000ff',
          // TODO: если передать vcId -> ничего не придет в ответ
          vcId: properties.deviceId || '00000000-0000-0000-0000-0000000000ff'
        }
      });
      const vehicleData = vehicleResponse?.result?.data || [];
      const vehicle = vehicleData?.[0] || [];

      // Дополнительная информация
      // TODO: Получаю пустоту, поэтому ничего не выводится
      const info = await jet.http.post('/rpc?d=jsonRpc', {
        type: 'query',
        query: '948e1fa3-414a-4568-b4ed-3a5535b239e9.dsp_getDspInfoFn',
        params: {
          attr: 3,
          uid: properties.deviceId,
          id: properties.deviceId
        }
      });
      const infoResult = info?.result?.data || {};

      // Положение на карте
      const osmUrl = 'https://nominatim.openstreetmap.org/reverse?format=json&zoom=18&addressdetails=1';
      const osm = await jet.http.get(`${osmUrl}&lat=${lastActiveData.lat}&lon=${lastActiveData.lon}`);

      // Формирование переменных для постановки

      const title = `${vehicle[2]} ${vehicle[3]} ${vehicle[1]}`;

      const navStatus = (lastActiveData.speed && lastActiveData.speed > 0)
        ? `В движении, скорость ${lastActiveData.speed} км/ч`
        : 'Стоит';

      const navTime = !!lastActiveData.time
        ? $utils.formatDate(new Date(lastActiveData.time), 'dd.MM.yyyy HH:mm')
        : '-';

      const navLonLat = `${lastActiveData.lat} / ${lastActiveData.lon}`;

      let regData = '';

      if (!!vehicleData[3]) {
        regData += `<br/>Маршрут № ${vehicleData[4]}`;

        if (!!vehicleData[11]) {
          const time1 = $utils.formatDate(new Date(vehicleData[13]), 'HH:mm');
          const time2 = $utils.formatDate(new Date(vehicleData[14]), 'HH:mm');

          regData += `, рейс № ${vehicleData[12]}: ${time1}-${time2}`;
        } else {
          regData += ` рейс не выполняется`;
        }
      }

      const el = document.getElementById('cardPopupVehicle');

      return this._renderParams(el.innerHTML, {
        Title: title,
        NavStatus: navStatus,
        NavTime: navTime,
        NavLonLat: navLonLat,
        OSM: osm.road || osm.display_name,
        RegData: regData,
        Carrier: vehicle[5] || 'Неизвестно',
        Route: `${vehicle[9] || 'Отсутствует'} (${vehicle[10] || 'отсут.'})`
      });
    },
    /**
     * Мини шаблонизатор
     * TODO: вынести куда-то в другое место, может в utils
     *
     * Принимемый объект @params должен содержать ключ и значение
     * Ключ преобразуется в [[key]] и подставится значение
     *
     * Важно! Ищется полное сопадение, поэтому пробелы ставить нельзя!
     *
     * @param {string} html Строка с html кодом для рендера
     * @param {Object} params Данные для шаблона
     * @returns {string}
     * @private
     */
    _renderParams(html, params) {
      Object.keys(params).forEach(key => {
        html = html.replace(`[[${key}]]`, params[key]);
      });

      return html;
    },
  },
  template: `
    <div class="fill-height" style="position: relative; width: 400px;">
    <v-layout fill-height column>
      <v-flex shrink>
        <CarrierListHeader
          :title="title"
          :loading="isLoading"
          @group="onGroup"
          @filter="onFilter"
        ></CarrierListHeader>
      </v-flex>

      <v-flex style="height: 100px">
        <div class="overflow-auto fill-height">
          <v-progress-linear
            indeterminate
            v-if="isLoading"
          ></v-progress-linear>

          <component
            v-else
            :is="listComponent"
            :vehicles="vehicles"
            :filter="currentFilter"
            :type="currentType"
            :items="items"
            :loading-data="loadingGroupingData"
            @start-tracking="tracking($event, true)"
            @stop-tracking="tracking($event, false)"
          ></component>
        </div>
      </v-flex>
    </v-layout>
    </div>
  `,
}; // CarrierList

// Основной компонент
export default {
  name: 'PtMonMapView',
  mounted() {
    $('.v-window-item--active .split > .v-toolbar').css({
      display: 'none',
    });

    setTimeout(() => {
      const elem = document.querySelector('.v-window-item--active .jet-collection-conte');

      if (elem) {
        elem.style.height = '100%';
      }
    }, 1);

    if (typeof mapboxgl === 'undefined') {
      this.onState(states.EVT_MAP_LOADING);

      const mapboxId = 'mapbox_ptMonMap_script';
      const mapInit = () => {
        this.mbxLoaded = true;

        this.$nextTick(() => {
          this._mapInit(true);
        });
      };

      if (document.getElementById(mapboxId)) {
        mapInit();
      } else {
        let s = document.createElement('link');

        s.setAttribute('rel', 'stylesheet');
        s.setAttribute('href', 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.css');
        document.getElementsByTagName('head')[0].appendChild(s);

        s = document.createElement('script');
        s.setAttribute('id', mapboxId);
        s.setAttribute('src', 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.5.0/mapbox-gl.js');
        document.getElementsByTagName('head')[0].appendChild(s);

        s.onload = () => {
          mapInit();
        };
      }
    } else {
      this.mbxLoaded = true;

      this._mapInit(true);
    }
  },
  created() {
    //_nats = jet.http.getWsNats();
    //_wsBus = _nats.connect(WS_URI);

    //window._nats = _nats; // TODO: remove it
    //window._wsBus = _wsBus; // TODO: remove it

    // Подписываемся на событие показа точек в журнале
    this.$root.$on(CarrierUtils.events.journalData, (data) => {
      const points = data.points || [];
      const vehicle = data.vehicle || {};
      const vehicleId = vehicle.id || null;
      const oldVehicleId = this.journal?.vehicle?.id || '';

      if (!points) {
        this.journal.data = [];
        this.journal.tempData = [];
      } else {
        if (vehicleId !== oldVehicleId) {
          this.journal.vehicle = vehicle;

          this.journal.data = points;
          this.journal.tempData = [];
        } else {
          if (!this.journal.tempData.length) {
            this.journal.tempData = _copy(this.journal.data);
          }

          this.journal.data = points;
        }
      }

      if (_hasOwnProperty(this.$refs, 'JournalSplit') && this.$refs['JournalSplit'] !== undefined) {
        this.$refs['JournalSplit'].reset();
      }
    });

    // Подписываемся на событие скрытия всех треков
    this.$root.$on(CarrierUtils.events.hideAllTracks, () => {
      this.journal.data = [];
    });
  },
  destroyed() {
    const elem = document.querySelector('.v-window-item--active .jet-collection-conte')

    if (elem) {
      elem.style.height = '';
    }

    $('.v-window-item--active .v-toolbar').css({
      display: '',
    });

    /*if (_sid !== null) {
      _wsBus.unsubscribe(_sid);
    }*/

    //_wsBus = null;
    //_nats = null;

    this.$root.$off(CarrierUtils.events.journalData);
    this.$root.$off(CarrierUtils.events.hideAllTracks);
  },
  data: () => ({
    loadData: false,
    mbxLoaded: false,
    title: '',
    ready: false,
    journal: {
      data: [],
      tempData: [],
      vehicle: null,
      // По-умолчанию - свернут
      minimized: true,
    },
    // Анимационный плеер
    player: {
      // Показываем или скрываем
      show: false,
      // Временная линия
      timeline: null,
      // Флаг что анимация проигрывается
      isPlay: false,
      // Конец временного отрезка
      endTime: null,
      // Текущая временная метка
      curTime: null,
      // Скорость уже увеличена на 10
      isIncreased: false,
      // Текущее время движения ТС
      posTime: '',
      // Время под курсором мыши над таймлинией
      timelineTimeTooltip: '',
    },
    layers: {
      itineraries: [],
      geozones: [],
      stops: [],
      routeObjects: [],
    },
  }),
  methods: {
    // Изменение состояние карты
    onState(state) {
      switch (state) {
        case states.EVT_MAP_LOADING:
          this.title = 'Загрузка карты...';
          break;

        case states.EVT_MAP_LOADED:
          this.title = 'On-line мониторинг';
          break;

        case states.EVT_VC_LOADING:
          this.title = 'Получение списка ТС...';
          break;

        case states.EVT_VC_LOAD:
          this.title = 'On-line мониторинг';
          this.ready = true;
          break;

        default:
          this.title = 'On-line мониторинг';
      }
    },
    // Сворачивание журнала
    minimizeJournal() {
      this.journal.minimized = !this.journal.minimized;
    },
    // По нажатию кнопки "Назад" в журнале
    backJournal() {
      if (this.journal.tempData.length) {
        this.journal.data = _copy(this.journal.tempData);
        this.journal.tempData = [];
      } else {
        this.journal.data = [];
        this.journal.tempData = [];
      }
    },
    // При выборе отображения слоев на карте
    onDrawLayers(data) {
      const itineraries = data.itineraries || [];
      const stops = data.stops || [];
      const geoZones = data.geozones || [];
      const stopTypes = data.routeObjects || [];

      this._drawRoutes(itineraries);
      this._drawStops(stops);
      this._drawGeoZones(geoZones);
      this._drawStopObjects(stopTypes);
    },
    // Запуск анимации на проигрывание
    showPlayer() {
      this.player.show = true;

      const map = Map.getMap();

      Map.createOrDataSource('player_track', {
        type: 'Feature',
        properties: {
          color: '#000',
          strokeColor: '#000',
          time: '',
        },
        geometry: {
          type: 'Point',
          coordinates: [],
        },
      });

      _playerPopup = new MapBoxGL.Popup({
        closeOnClick: false,
        closeButton: false,
        className: 'player_popup',
      })
        .setText(this.journal.vehicle.gov)
        .setLngLat([
          this.journal.data[0].lon,
          this.journal.data[0].lat,
        ])
        .addTo(map);

      if (!map.getLayer('player_track')) {
        map.addLayer({
          id: 'player_track',
          type: 'circle',
          source: 'player_track',
          paint: {
            'circle-color': ['get', 'color'],
            'circle-radius': 8,
            'circle-stroke-width': 2,
            'circle-stroke-color': ['get', 'strokeColor'],
          },
        });
      }

      const pPoints = this.journal.data;
      const startTime = pPoints[0].time;

      PtMonMapService.init(pPoints, (lon, lat, curTime) => {
        const sData = Map.getMap().getSource('player_track')._data;

        const prevCoords = sData.geometry.coordinates;

        if (prevCoords[0] === lon && prevCoords[1] === lat) {
          // Стоит
          sData.properties.color = 'rgba(101, 73, 133, 1)';
          sData.properties.strokeColor = 'rgba(101, 73, 133, .4)';
        } else {
          // Движется
          sData.properties.color = 'rgba(22, 179, 22, 1)';
          sData.properties.strokeColor = 'rgba(22, 179, 22, .4)';
        }

        sData.geometry.coordinates = [lon, lat];
        _playerPopup.setLngLat([lon, lat]);

        const time = new Date(startTime + curTime);

        const hours = setPadding(time.getHours());
        const minutes = setPadding(time.getMinutes());
        const seconds = setPadding(time.getSeconds());

        this.$set(this.player, 'posTime', `${hours}:${minutes}:${seconds}`);

        Map.createOrDataSource('player_track', _copy(sData));
      }, (svg) => {
        if (svg) {
          this.player.timeline = svg;

          this.$nextTick(() => {
            if (this.$refs.timeline) {
              this.$refs.timeline.innerHTML = svg.innerHTML;
            }
          });
        } else {
          this.$refs.timeline.innerHTML = '';

          this.player.timeline = null;
        }
      }, (time, endTime) => {
        this.player.curTime = time;
        this.player.endTime = endTime;
      });

      this.player.isPlay = true;

      PtMonMapService.playAnimation();
    },
    // Скрытие плеера
    hidePlayer() {
      PtMonMapService.stopAnimation();

      if (_playerPopup !== null) {
        _playerPopup.remove();

        _playerPopup = null;
      }

      this.player = {
        show: false,
        isPlay: false,
        curTime: null,
        endTime: null,
        timeline: null,
        isIncreased: false,
      };

      Map.createOrDataSource('player_track', {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [],
        },
      });
    },
    // Остановка анимации
    stopAnimation() {
      PtMonMapService.stopAnimation();

      this.player.isPlay = false;
      this.player.curTime = null;
    },
    // Продолжить/Пауза воспроизведения
    playOrPauseAnimation() {
      if (this.player.isPlay) {
        PtMonMapService.pauseAnimation();
      } else {
        PtMonMapService.playAnimation();
      }

      this.player.isPlay = !this.player.isPlay;
    },
    // При клике на тайм линии
    onTimelinePosChange(e) {
      PtMonMapService.setCurrentTime(
        e.offsetX / this.$refs.timeline.offsetWidth
      );

      this.player.isPlay = true;
    },
    // Увеличить/Уменьшить скорость анимации
    incOrDecAnimSpeed() {
      this.player.isIncreased = !this.player.isIncreased;

      PtMonMapService.setSpeedCoeff(
        this.player.isIncreased ? 100 : 10
      );
    },
    // Вождение мышки над таймлайн
    onTimelineMouseMove(event) {
      const p = event.offsetX / this.$refs.timeline.offsetWidth;
      const startTime = this.journal.data[0].time;
      const percent = p > 1 ? p / 100 : p;
      const fullTime = PtMonMapService.getAnimate().fullTime;

      const time = new Date(startTime + parseInt(`${fullTime * percent}`, 10));

      const hours = setPadding(time.getHours());
      const minutes = setPadding(time.getMinutes());
      const seconds = setPadding(time.getSeconds());

      this.player.timelineTimeTooltip = `${hours}:${minutes}:${seconds}`;
    },
    // Отрисовка маршрута
    async _drawRoutes(itineraries) {
      const ids = itineraries.map(it => it.id);

      // TODO: пока приходит только 1
      if (ids.length) {
        const promises = [];

        ids.forEach(it => {
          promises.push(
            MapLayersService.getRoutePoint(it)
          );
        });

        Promise.all(promises)
          .then(responses => {
            const routes = [];

            responses.forEach((it, index) => {
              routes.push({
                id: ids[index],
                points: it,
              });
            });

            Map.drawLayerRoutes(routes);
          });

        // const points = await MapLayersService.getRoutePoint(ids[0]);
        //
        // Map.drawLayerRoutes([{
        //   id: ids[0].it,
        //   points: points,
        // }]);
      } else {
        Map.drawLayerRoutes([]);
      }
    },
    // Отрисовка остановок
    _drawStops(stops) {
      Map.drawStops(stops);
    },
    // Отрисовка гео зон
    _drawGeoZones(geoZones) {
      geoZones = geoZones || [];

      Map.drawGeoZones(
        geoZones.map(it => MapLayersService.parseWkx(it))
      );
    },
    // Отрисовка объектов маршрута
    _drawStopObjects(stopObjects) {
      MapObjectsService.init(Map);

      MapObjectsService.drawStopObjects(
        _copy(stopObjects || [])
      );
    },
    // Инициализация карты
    _mapInit(reload = false) {
      // Создание карты
      Map.init({
        container: document.getElementById('mon_map')
      }, reload).then(() => {
        this.onState(states.EVT_MAP_LOADED)
      });
    },
    // Статус машинки
    _vehicleStatus(vehicle) {
      let result;

      switch (vehicle.state) {
        case 0:
          result = 'На связи';
          break;
        case 24:
          result = 'Потеря связи с датчиком от 2 до 24 часов';
          break;
        case 72:
          result = 'Потеря связи с датчиком от 24 до 72 часов';
          break;
        case 100:
          result = 'Потеря связи с датчиком свыше 72 часов';
          break;
        default:
          result = 'Нет данных';
          break;
      }

      return result;
    },
  },
  computed: {
    // Стили инструментов карты
    mapToolsStyle() {
      return {
        position: 'absolute',
        top: '20px',
        right: '20px',
      };
    },
    // Стили полоски временной линии
    timelinePercentStyle() {
      const width = this.player.endTime === null || this.player.curTime === null
        ? '0'
        : ((this.player.curTime * 100) / this.player.endTime) + '%';

      return {
        width: width,
      };
    },
  },
  template: `
    <v-container class="jet-map-content fill-height pa-0" fluid align-start>
    <v-layout class="fill-height">
      <CarrierList :title="title"/>

      <v-flex class="fill-height">
        <Split class="fill-height" ref="JournalSplit" direction="vertical">
          <SplitArea :size="journal.minimized ? 95 : 75" style="position:relative;">
            <div id="mon_map" class="fill-height" style="position: relative;"/>

            <div :style="mapToolsStyle">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <MapLayers
                    v-on="on"
                    v-model="layers"
                    @onSubmit="onDrawLayers"
                  ></MapLayers>
                </template>

                <span>Слои на карте</span>
              </v-tooltip>
            </div>
          </SplitArea>

          <SplitArea :size="journal.minimized ? 10 : 25">
            <v-layout fill-height column style="overflow: visible;">
              <v-flex shrink style="position:relative;">
                <transition appear name="slide-fade" mode="out-in">
                  <template v-if="player.show">
                    <v-layout align-center class="primary" style="height:44px;">
                      <v-flex shrink class="text-no-wrap">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              dark icon
                              v-on="on"
                              v-bind="attrs"
                              @click="stopAnimation"
                            >
                              <v-icon>mdi-stop</v-icon>
                            </v-btn>
                          </template>

                          Остановить проигрывание
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              dark icon
                              v-on="on"
                              v-bind="attrs"
                              @click="playOrPauseAnimation"
                            >
                              <v-icon>
                                mdi-{{ player.isPlay ? 'pause' : 'play' }}
                              </v-icon>
                            </v-btn>
                          </template>

                          {{ player.isPlay ? 'Приостановить' : 'Запустить' }} анимацию
                        </v-tooltip>

                        <v-tooltip top open-delay="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              dark outlined small
                              v-on="on"
                              v-bind="attrs"
                              @click="incOrDecAnimSpeed"
                            >
                              {{ player.isIncreased ? '/' : 'x' }} 10
                            </v-btn>
                          </template>

                          {{ player.isIncreased ? 'Уменьшить' : 'Увеличить' }} скорость
                        </v-tooltip>
                      </v-flex>

                      <v-flex shrink class="ml-4">
                        <span style="color: white;">
                          {{ player.posTime || '' }}
                        </span>
                      </v-flex>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-flex
                            class="ml-4 py-1 fill-height"
                            style="position:relative;"
                            v-on="on"
                            v-bind="attrs"
                            @mousemove="onTimelineMouseMove"
                          >
                            <div class="fill-height" style="position:relative;">
                              <div
                                style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;"
                                @click="onTimelinePosChange"
                              >
                                <div
                                  ref="timeline_percent"
                                  class="timeline_percent"
                                  :style="timelinePercentStyle"
                                ></div>
                              </div>

                              <div ref="timeline" class="timeline_graph fill-height"/>
                            </div>
                          </v-flex>
                        </template>

                        {{ player.timelineTimeTooltip }}
                      </v-tooltip>

                      <v-flex shrink>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              dark icon
                              v-on="on"
                              v-bind="attrs"
                              @click="hidePlayer"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </template>

                          Закрыть плеер
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                  </template>

                  <template v-if="!player.show">
                    <v-row dense class="primary px-3 align-center">
                      <v-col class="white--text font-weight-bold">
                        <v-btn
                          icon
                          color="white"
                          v-if="journal.tempData && journal.tempData.length"
                          @click="backJournal"
                        >
                          <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>

                        Журнал событий телеметрии
                        <template v-if="(journal?.data || []).length && journal?.vehicle?.gov">
                          |
                          {{ journal?.vehicle?.gov || '' }} |
                          {{ (journal?.data || []).length }} записей
                        </template>
                      </v-col>

                      <v-col cols="2" class="text-right">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="white"
                              v-on="on"
                              v-bind="attrs"
                              :disabled="journal.data.length === 0"
                              @click="showPlayer"
                            >
                              <v-icon>mdi-play</v-icon>
                            </v-btn>
                          </template>

                          Запустить анимацию
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="white"
                              v-on="on"
                              v-bind="attrs"
                              @click="minimizeJournal"
                            >
                              <v-icon>
                                mdi-chevron-{{ journal.minimized ? 'up' : 'down' }}
                              </v-icon>
                            </v-btn>
                          </template>

                          {{ journal.minimized ? 'Развернуть' : 'Свернуть' }} журнал
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </template>
                </transition>
              </v-flex>

              <v-flex style="height: 10px; overflow: auto">
                <template v-if="!player.show && journal.data.length">
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th>Тип события</th>
                        <th>Время события</th>
                        <th>Скорость</th>
                        <th>Местоположение</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="it in journal.data" :key="it.id">
                        <td>{{ _vehicleStatus(journal.vehicle) }}</td>
                        <td>
                          {{ new Date(it.time).toLocaleDateString() }}
                          {{ new Date(it.time).toLocaleTimeString() }}
                        </td>
                        <td>{{ parseInt(it.speed) }} км/ч</td>
                        <td>
                          {{ parseFloat(it.lat).toFixed(5) }};
                          {{ parseFloat(it.lon).toFixed(5) }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>

                <template v-else>
                  <div class="pt-4 text-center text--darken-1">
                    Необходимо выбрать трек для ТС
                  </div>
                </template>
              </v-flex>
            </v-layout>
          </SplitArea>
        </Split>
      </v-flex>
    </v-layout>

    <div style="display: none;" id="cardPopupPreloader">
      <v-card class="mx-auto" max-width="470" width="400" tile flat>
        <v-card-subtitle>
          Подождите, происходит загрузка
        </v-card-subtitle>

        <v-card-text class="text-center">
          <v-progress-circular indeterminate/>
        </v-card-text>
      </v-card>
    </div>

    <div style="display: none;" id="cardPopupVehicle">
      <v-card class="mx-auto" max-width="470" width="400" tile flat>
        <v-card-title>[[Title]]</v-card-title>

        <v-card-text class="text--primary">
          <div><b>Статус:</b> [[NavStatus]]</div>
          <div><b>Время:</b> [[NavTime]]</div>
          <div><b>Перевозчик:</b> [[Carrier]]</div>
          <div><b>Маршрут №</b> [[Route]]</div>
          <div><b>ш / д:</b> [[NavLonLat]]</div>
          <div>[[OSM]]</div>

          <div>[[RegData]]</div>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn class="vehicle_close" text>
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    </v-container>
  `
};
</script>

<style scoped>
.timeline_graph {
  background: white;

  border-radius: 4px;
}

.timeline_percent {
  position: absolute;

  left: 0;
  top: 0;

  height: 100%;

  background-color: var(--v-primary-base);
  opacity: 0.4;
}

>>> .timeline_graph svg {
  height: 100%;
  display: block;
}

/* Треугольник */

>>> .player_popup.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  border-bottom: none;
  border-left: none;
  border-top-color: var(--v-primary-base);
}

>>> .player_popup.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  border-top: none;
  border-left: none;
  border-bottom-color: var(--v-primary-base);
}

>>> .player_popup.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-top: none;
  border-right: none;
  border-bottom-color: var(--v-primary-base);
}

>>> .player_popup.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-bottom: none;
  border-right: none;
  border-top-color: var(--v-primary-base);
}

>>> .player_popup.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: var(--v-primary-base);
}

>>> .player_popup.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--v-primary-base);
}

>>> .player_popup.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: var(--v-primary-base);
}

>>> .player_popup.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: var(--v-primary-base);
}

/* ********** */

>>> .player_popup .mapboxgl-popup-content {
  padding: 10px;
  border: 2px solid var(--v-primary-base);
  border-radius: 6px;
  font-weight: bold;
}
</style>
